<template>
  <v-dialog
      v-model="dialog"
      max-width="350"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>Export price CSV</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" v-if="currentUser.access_type == 'federal'">
                <v-select
                    class="mt-2"
                    label="Type *"
                    v-model="product.score_type"
                    :error="$v.product.score_type.$error"
                    :items="types"
                    item-text="name"
                    item-value="value"
                    clearable
                    outlined
                    dense
                >
                </v-select>
                <span class="text-danger" v-if="$v.product.score_type.$error">This information is required</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" v-if="product.score_type == 'score' || currentUser.access_type == 'score'">
                <v-select
                    class="mt-2"
                    label="Score *"
                    v-model="product.score_id"
                    :error="$v.product.score_id.$error"
                    :items="scores"
                    :disabled="currentUser.access_type == 'score'"
                    item-text="name"
                    item-value="id"
                    clearable
                    outlined
                    dense
                >
                </v-select>
                <span class="text-danger" v-if="$v.product.score_id.$error">This information is required</span>
              </v-col>
            </v-row>
          </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="cancel-btn"
            text
            x-large
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            color="btn btn-primary"
            dark
            x-large
            @click="exportProduct()"
        >
          Export
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, requiredIf} from "vuelidate/lib/validators";
import ProductService from "@/services/product/ProductService";
import ScoreService from "@/services/score/score/ScoreService";

const product = new ProductService();
const score = new ScoreService();
export default {
  name: "ExportProduct",
  data() {
    return {
      dialog: false,
      scores: [],
      search: {},
      types: [
        { name: "Federal", value: "federal" },
        { name: "Score", value: "score" },
      ],
      product: {
        score_type: '',
        score_id: '',
      },
      currentUser: {}
    }
  },
  validations: {
    product: {
      score_type: { required },
      score_id: { required: requiredIf(product => product.score_type == 'score') },
    }
  },
  mounted() {
    this.currentUser = this.$store.getters.currentUser;
    if(this.currentUser.access_type == 'score') {
      this.product.score_type = 'score';
      this.product.score_id = this.currentUser.score_id;
    }
    this.getAllScore();
  },
  methods: {
    openDialog(search) {
      this.dialog = true;
      this.search = search;
    },
    closeDialog() {
      this.dialog = false;
      this.$v.$reset();
      this.search = {};
      this.product = {
        score_type: '',
        score_id: '',
      }
    },
    getAllScore() {
      score.all().then(response => {
        this.scores = response.data.scores;
      }).catch( err => {});
    },
    exportProduct() {
      this.$v.$touch();
      if(this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        },20000);
      }else {
        this.product = Object.assign(this.search, this.product);
        product.exportProduct(this.product);
        this.closeDialog();
        this.resetForm();
      }
    },
    resetForm() {
      this.$v.$reset();
      this.product =  {
        score_type: '',
        score_id: '',
      }
      if(this.currentUser.access_type == 'score') {
        this.product.score_type = 'score';
        this.product.score_id = this.currentUser.score_id;
      }
    }
  }
}
</script>

<style scoped>

</style>