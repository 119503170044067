<template>
  <v-dialog
      v-model="dialog"
      max-width="350"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>Import product price</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12" v-if="currentUser.access_type == 'federal'">
              <v-select
                  class="mt-2"
                  label="Type *"
                  v-model="product.type"
                  :error="$v.product.type.$error"
                  :items="types"
                  item-text="name"
                  item-value="value"
                  clearable
                  outlined
                  dense
              >
              </v-select>
              <span class="text-danger" v-if="$v.product.type.$error">This information is required</span>
            </v-col>
            <v-col cols="12" md="12" v-if="product.type == 'score' || currentUser.access_type == 'score'">
              <v-select
                  class="mt-2"
                  label="Score *"
                  v-model="product.score_id"
                  :error="$v.product.score_id.$error"
                  :items="scores"
                  :disabled="currentUser.access_type == 'score'"
                  item-text="name"
                  item-value="id"
                  clearable
                  outlined
                  dense
              >
              </v-select>
              <span class="text-danger" v-if="$v.product.score_id.$error">This information is required</span>
            </v-col>
            <v-col cols="12" md="12" v-if="product.score_id != null || product.type == 'default'">
              <v-file-input
                  class="mt-2"
                  label="Select price csv file*"
                  prepend-icon=""
                  prepend-inner-icon="mdi-file"
                  accept=".csv"
                  v-model="product.file"
                  :error="$v.product.file.$error"
                  clearable
                  outlined
                  dense
              >
              </v-file-input>
              <span class="text-danger" v-if="$v.product.score_id.$error">This information is required</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="cancel-btn"
            text
            x-large
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            color="btn btn-primary"
            dark
            x-large
            @click="importPrice()"
            :loading="loading"
        >
          Import
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required, requiredIf} from "vuelidate/lib/validators";
import ProductService from "@/services/product/ProductService";
import ScoreService from "@/services/score/score/ScoreService";

const product = new ProductService();
const score = new ScoreService();
export default {
  name: "ImportPrice",
  data() {
    return {
      dialog: false,
      loading: false,
      scores: [],
      search: {},
      types: [
        { name: "Default pricing", value: "default" },
        { name: "SCORE based pricing", value: "score" },
      ],
      product: {
        type: '',
        score_id: null,
        file: null,
      },
      currentUser: {}
    }
  },
  validations: {
    product: {
      type: { required },
      score_id: { required: requiredIf(product => product.type == 'score') },
      file: { required },
    }
  },
  mounted() {
    this.currentUser = this.$store.getters.currentUser;
    if(this.currentUser.access_type == 'score') {
      this.product.type = 'score';
      this.product.score_id = this.currentUser.score_id;
    }
    this.getAllScore();
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.$v.$reset();
      this.search = {};
      this.product = {
        type: '',
        score_id: '',
      }
    },
    getAllScore() {
      score.all().then(response => {
        this.scores = response.data.scores;
      }).catch( err => {});
    },
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.product) {
        if (key === "file" && this.product[key] != null && this.product[key] != undefined) {
          formData.append('file', this.product[key]);
        }else {
          if(this.product[key]){
            formData.append(key,this.product[key]);
          }
        }
      }
      return formData;
    },
    importPrice() {
      this.$v.$touch();
      if(this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        },20000);
      }else {
        this.loading = true;
        let formData = this.convertToFormData();
        product.importPrice(formData).then(response => {
          if(response.data.status == "OK") {
            this.$snotify.success("Import Successfully !!");
            this.closeDialog();
            this.resetForm();
            this.loading = false;
          }else {
            this.$snotify.error(response.data.message);
          }
        }).catch(()=> {
          this.loading = false;
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    resetForm() {
      this.$v.$reset();
      this.product =  {
        type: '',
        score_id: null,
        file: null,
      }
      if(this.currentUser.access_type == 'score') {
        this.product.type = 'score';
        this.product.score_id = this.currentUser.score_id;
      }
    }
  }
}
</script>